import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Since we launched PayPerks`}{`®`}{` to provide financial education for cardholders in the U.S. Treasury's `}<a parentName="p" {...{
        "href": "https://www.usdirectexpress.com"
      }}>{`Direct Express`}{`®`}{` Debit MasterCard`}{`®`}</a>{` card program several years ago, nearly 700,000 of those cardholders have registered for PayPerks, accessing the program through either a computer or mobile device. So, when we were invited to collaborate with the U.S. Treasury, Comerica Bank, MasterCard`}{`®`}{` and Xerox on the strategy and marketing of the new `}<a parentName="p" {...{
        "href": "https://help.payperks.com/hc/en-us/articles/214452878"
      }}>{`Direct Express`}{`®`}{` DX`}{`℠`}{` mobile app`}</a>{`, we were excited to see how PayPerks users would respond.`}</p>
    <p>{`It wasn't too long ago that desktop computers were the primary way underserved users were accessing the Internet. However, as the chart below indicates, the way our users connect with PayPerks has changed dramatically over the past two years, reflecting the dominance of mobile access.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABo0lEQVQoz11R23KsIBD0/z/r5Dl5OHEVL9l1XRFR1ktkgWFGPaWbnKqkqwu6uhgYegIiQqJrWZ4/duRZlqQJSxLG2MvLn9fXt6Io3sMwiuPz+ZymaZ7lALBtGyIGiLj9xLqu27ZpPYencBjH/84v7MX3+70fRgeeEJ3zAB4AHMDzUiKCp4uI6+oRPYDTGq0looDzqqx4Ut7HT3384Iue0AI4Y3Ahj94+HlMt+jzr80wxBlrTsgRCiFbK8eHiaxfFV5beIlbGye0a55Kl1d/wFsbleyRO0Xwr7Di4h7bW0rLsLzeNaJVy4zhlKY+YYCk/MRGn/Fzym2RF93ER2aXJqv7S6n52w2yNcdu6IFLA65qXpYpjPU2eENDDviJ478CBs/6Qxpiun/JKZWUXF+3nw60LBUqprpXovUfcs/H+SOwQe/0e3iGB0K+E60LGOgu4t22M4ZxLKb33xpq65vM8L8sipWxkQ0Ra64pXWut13bquq4X4PefnJA3QVYxd1yqlmqaRUg7jhNOkiovsdjjnfszZHTg6dfbJA9+7dQe/za+TT/EPmmxtv2OjVzoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/f94489bd2dbf95536e89fbfc5dcf3610/7f61c/PayPerks_MobilevsDesktop_Chart_20160601.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f94489bd2dbf95536e89fbfc5dcf3610/b0a15/PayPerks_MobilevsDesktop_Chart_20160601.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/f94489bd2dbf95536e89fbfc5dcf3610/e17e5/PayPerks_MobilevsDesktop_Chart_20160601.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f94489bd2dbf95536e89fbfc5dcf3610/0b533/PayPerks_MobilevsDesktop_Chart_20160601.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f94489bd2dbf95536e89fbfc5dcf3610/0b533/PayPerks_MobilevsDesktop_Chart_20160601.png",
            "alt": "PayPerks Mobile vs Desktop Chart",
            "title": "PayPerks Mobile vs Desktop Chart",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`As explained in `}<a parentName="p" {...{
        "href": "https://www.treasury.gov/connect/blog/Pages/Direct-Express-Mobile-App-Delivers-Financial-Capability-to-Those-Most-in-Need.aspx"
      }}>{`this post`}</a>{` by the U.S. Treasury's Walt Henderson and `}<a parentName="p" {...{
        "href": "http://newsroom.mastercard.com/2016/04/28/using-mobile-technology-to-drive-financial-inclusion-with-direct-express/"
      }}>{`this post`}</a>{` by MasterCard's Andrew Gillen, the Direct Express`}{`®`}{` program team observed several opportunities to increase cardholder convenience through the DX`}{`℠`}{` mobile app.`}</p>
    <p>{`Our team was brought in to help in two areas: (1) to integrate cardholder education into the app itself, and (2) to help ensure that the roll-out was as successful as possible, by capitalizing on the insights we have gained over the past several years about these cardholders.`}</p>
    <p>{`Here's a brief recap of how we did that and the outcomes we have seen so far:`}</p>
    <p><strong parentName="p">{`Phase 1, Content development:`}</strong>{` The first thing we did was create an educational campaign designed to let users know the features and benefits of the app. We felt education would be critical to the success of this effort. Among other things, we knew many Direct Express`}{`®`}{` cardholders are new to smartphones and might not have much experience with downloading and using apps. Several PayPerks modules were developed covering topics such as how to use the DX`}{`℠`}{` mobile app to find in-network ATMs and where and how to download the DX`}{`℠`}{` mobile app. Through our incentive model, we were able to drive nearly 200K module completions on the app, thus greatly expanding awareness among the Direct Express`}{`®`}{` cardholder population.`}</p>
    <p><strong parentName="p">{`Phase 2, Audience Segmentation:`}</strong>{` Next, we segmented our user base to identify the optimal segments for which to test and learn. We analyzed six data points as proxies for engagement and smartphone utilization so that we could recruit a strong initial cohort of early adopters.`}</p>
    <p><strong parentName="p">{`Phase 3, Targeted outreach:`}</strong>{` We then customized more than a dozen campaigns to test messaging and design among our target segments. In addition to wanting to maximize open and conversion rates on our email campaigns informing cardholders about the availability of the new DX`}{`℠`}{` mobile app, we also wanted to optimize the downloading and onboarding process of the actual app.`}</p>
    <p><strong parentName="p">{`Phase 4, Measure. Learn. Repeat:`}</strong>{` We paired quantitative measurements of the conversion funnel with qualitative insights from our users to iterate and improve on the sign-up flow. We collected more than 60,000 survey responses`}{`—`}{`both from those who successfully downloaded the DX`}{`℠`}{` mobile app and those who did not`}{`—`}{`to ensure the DX`}{`℠`}{` mobile app was positioned for inclusive growth.`}</p>
    <p><strong parentName="p">{`Phase 5, Promote at scale:`}</strong>{` Our test campaigns gave us a good indication of what capacity to expect when we were ready to market at scale through both PayPerks and other Direct Express`}{`®`}{` channels. We worked`}{`—`}{`and continue to work`}{`—`}{`closely with the Direct Express`}{`®`}{` program team through the ramp-up period to ensure a smooth and steady adoption curve and a great user experience once signed up.`}</p>
    <p>{`While the DX`}{`℠`}{` mobile app has been available for only a few months, hundreds of thousands of cardholders have downloaded it and are giving it rave reviews. In a recent survey of those who had downloaded, it, 60% of comments used the words "Love," "great," "perfect," "excellent," "awesome," "cool," or "fun". Some examples are below:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"I love being able to get my balance without having to call."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"I like the mobile app because helps me keep up where money is going…"`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Great app and customer service help."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Glad you finally got an app! Makes my life a lot easier."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Awesome! It's so very helpful for me. The app has made my life easier!"`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Awesome app! It was about time too."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Very user friendly! I truly love this app!"`}</p>
    </blockquote>
    <p>{`The PayPerks partnership with the Direct Express`}{`®`}{` program team has been lauded, since launch, for turning government payments into a teachable moment for cardholders. But what this project demonstrated`}{`—`}{`to us and the entire Direct Express`}{`®`}{` program`}{`—`}{`is how PayPerks' robust and real-time, two-way dialogue with cardholders can teach card providers about our users and the 'whys' behind their behavior, among other things.`}</p>
    <p>{`We look forward to putting these insights to good use for the benefit of both cardholders and program administrators as smart phone adoption continues to steadily increase.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      